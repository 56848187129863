<template>
<div id="miel-store">
    <v-dialog scrollable v-model="dialog" persistent max-width="900" transition="dialog-top-transition">
        <v-card :loading="loading" :disabled="loading">
            <v-toolbar class="elevation-custom-table">
                <v-spacer></v-spacer>
                <span class="font-weight-bold">Nuevo elemento</span>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-file-input accept="image/png, image/jpeg, image/webp" multiple v-model="imagenes" truncate-length="15" ref="imageinput" class="d-none" @change="proccessImage"></v-file-input>
            <v-carousel :show-arrows="false" hide-delimiters v-model="model" height="auto" v-if="imagenes.length > 0">
                <v-carousel-item v-for="imagen in imagenes" :key="imagen.name" contain>
                    <v-img height="300" max-height="300" :src="parseBlob(imagen)" class="grey darken-4" contain>
                    </v-img>
                </v-carousel-item>
            </v-carousel>
            <v-toolbar flat color="grey darken-3" class="px-2" v-if="imagenes.length > 0">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon @click="$refs.imageinput.$refs.input.click()" v-bind="attrs" v-on="on">
                            <v-icon color="white">mdi-paperclip</v-icon>
                        </v-btn>
                    </template>
                    <span>Seleccionar archivos</span>
                </v-tooltip>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon @click="model--" v-bind="attrs" v-on="on">
                            <v-icon color="white">mdi-chevron-left</v-icon>
                        </v-btn>
                    </template>
                    <span>Anterior</span>
                </v-tooltip>
                <span class="white--text body-2 font-weight-bold">{{model+1}} / {{imagenes.length}}</span>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon @click="model++" v-bind="attrs" v-on="on">
                            <v-icon color="white">mdi-chevron-right</v-icon>
                        </v-btn>
                    </template>
                    <span>Siguiente</span>
                </v-tooltip>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon @click="deleteImage(imagenes[model])" v-bind="attrs" v-on="on">
                            <v-icon color="white">mdi-delete</v-icon>
                        </v-btn>
                    </template>
                    <span>Remover imagen</span>
                </v-tooltip>
            </v-toolbar>
            <v-img height="160" class="grey darken-3" v-if="imagenes.length == 0">
                <v-card-title class="align-center fill-height" primary-title>
                    <v-spacer></v-spacer>
                    <v-btn class="text-none" outlined color="grey darken-2" depressed @click="$refs.imageinput.$refs.input.click()">
                        <v-icon small left color="white">mdi-paperclip</v-icon>
                        <span class="white--text">Seleccionar imagenes</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-title>
            </v-img>
            <v-card-text class="pt-5">
                <v-row>
                    <v-col cols="12" class="mb-2">
                        <v-text-field v-model="miel.tipoDeMiel" color="primary" label="Tipo de miel" persistent-placeholder hide-details>
                            <template #label>
                                Tipo de miel<span class="red--text"><strong> *</strong></span>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" class="mb-2">
                        <v-text-field v-model="miel.nombre" label="Nombre" color="primary" persistent-placeholder hide-details>
                            <template #label>
                                Nombre<span class="red--text"><strong> *</strong></span>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="6" class="mb-2">
                        <v-text-field v-model="miel.origenBotanico" label="Origen botánico" color="primary" persistent-placeholder hide-details>
                            <template #label>
                                Origen botánico<span class="red--text"><strong> *</strong></span>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="6" class="mb-2">
                        <v-text-field v-model="miel.granosDePolen" label="Granos de polen" color="primary" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-col cols="12" class="mb-2">
                        <v-text-field v-model="miel.mielDeAbeja" label="Miel de abeja" color="primary" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-col cols="12" class="mb-2">
                        <v-text-field v-model="miel.tipoDeVegetacion" label="Tipo de vegetación" color="primary" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-col cols="12" class="mb-2">
                        <v-text-field v-model="miel.usos" label="Usos" color="primary" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-col cols="6" class="mb-2">
                        <v-menu ref="menu" :close-on-content-click="false" :return-value.sync="miel.fechaDeRecoleccion" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field :value="formatDate(miel.fechaDeRecoleccion)" label="Fecha de recolección" persistent-placeholder readonly v-bind="attrs" v-on="on" hide-details></v-text-field>
                            </template>
                            <v-date-picker v-model="miel.fechaDeRecoleccion" no-title scrollable color="primary">
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="menu = false" class="text-none">
                                    Cancelar
                                </v-btn>
                                <v-btn text color="primary" @click="$refs.menu.save(miel.fechaDeRecoleccion)" class="text-none">
                                    Aceptar
                                </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" class="mb-2">
                        <v-text-field v-model="miel.estado" label="Estado" color="primary" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-col cols="12" class="mb-2">
                        <v-text-field v-model="miel.municipio" label="Municipio" color="primary" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-col cols="6" class="mb-2">
                        <v-text-field v-model="miel.nombreDelRecolector" label="Nombre de el recolector" color="primary" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-col cols="6" class="mb-2">
                        <v-text-field v-model="miel.nombreDelApicultor" label="Nombre de el apicultor" color="primary" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-col cols="6" class="mb-2">
                        <v-text-field v-model="miel.Donante" label="Donante" color="primary" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-col cols="12" class="mb-2">
                        <v-text-field v-model="miel.claveDeColeccion" label="Clave de colección" color="primary" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-col cols="12" class="mb-2">
                        <v-text-field v-model="miel.publicacionAsociada" label="Publicación asociada" color="primary" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-col cols="12" class="mb-2">
                        <v-text-field v-model="miel.proyecto" label="Proyecto" color="primary" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="text-none" color="grey darken-2" text @click="$router.push('/mieles')">
                    Cancelar
                </v-btn>
                <v-btn color="green darken-1" class="text-none" text @click="store">
                    Agregar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog scrollable v-model="unsaved" persistent max-width="300" transition="dialog-top-transition">
        <v-card color>
            <v-toolbar flat>
                <v-spacer></v-spacer>
                <span class="font-weight-bold">Alerta</span>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text class="text-center">
                Tus cambios no se guardaran, ¿deseas continuar?
            </v-card-text>
            <v-card-actions>
                <v-btn text class="text-none" color="grey darken-1" @click="unsaved = false">Cancelar</v-btn>
                <v-spacer></v-spacer>
                <v-btn text class="text-none" color="red" @click="$router.push('/mieles')">Salir sin guardar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
export default {
    name: 'mielStore',
    data: () => ({
        loading: false,
        unsaved: false,
        dialog: true,
        model: 0,
        imagenes: [],
        miel: {
            tipoDeMiel: null,
            nombre: null,
            origenBotanico: null,
            granosDePolen: null,
            mielDeAbeja: null,
            tipoDeVegetacion: null,
            usos: null,
            fechaDeRecoleccion: null,
            estado: null,
            municipio: null,
            nombreDelApicultor: null,
            nombreDelRecolector: null,
            donante: null,
            claveColeccion: null,
            publicacionAsociada: null,
            proyecto: null
        },
        months: [
            { value: 'Enero' },
            { value: 'Febrero' },
            { value: 'Marzo' },
            { value: 'Abril' },
            { value: 'Mayo' },
            { value: 'Junio' },
            { value: 'Julio' },
            { value: 'Agosto' },
            { value: 'Septiembre' },
            { value: 'Octubre' },
            { value: 'Noviembre' },
            { value: 'Diciembre' },
        ]
    }),
    methods: {
        parseBlob(imagen){
            return URL.createObjectURL(imagen);
        },
        deleteImage(imagen) {
            let index = this.imagenes.findIndex(img => img.name == imagen.name);
            this.imagenes.splice(index, 1);
        },
        proccessImage() {
            this.imagenes.forEach(imagen => {
                if (imagen.type != 'image/png' && imagen.type != 'image/jpg' && imagen.type != 'image/jpeg' && imagen.type != 'image/webp') {
                    let index = this.imagenes.findIndex(img => img.name == imagen.name);
                    this.imagenes.splice(index, 1);
                }
            })
        },
        formatDate(date) {
            if (date != null) {
                const dateArray = date.split('-');
                const dateObject = new Date(dateArray[0], dateArray[1] - 1, dateArray[2]);
                return dateObject.toLocaleDateString('es-MX');
            }
        },
        async store() {
            this.loading = true;
            const form = new FormData();
            form.append('miel', JSON.stringify(this.miel));
            this.imagenes.forEach((imagen) => {
                form.append('files', imagen);
            })
            this.$axios.post('/api/miel', form).then(response => {
                if (response.status == 201) {
                    this.$store.commit('miel/INSERT_MIEL', response.data);
                    this.$store.commit('snackbar/SHOW_SNACKBAR', { message: 'Elemento agregado.' });
                    this.$router.push('/mieles');
                }
            }).catch(error => {
                this.$store.commit('error/SHOW_ERROR', { message: error });
            }).then(() => {
                this.loading = false;
            })
        }
    },
    props: {
        height: Number
    }
}
</script>
